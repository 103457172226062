import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`projects`}</h1>
    <div className={styles.fadeInChildren}>
      <p><a parentName="p" {...{
          "href": "/project/art-vr"
        }}>{`Art/VR`}</a></p>
      <p><a parentName="p" {...{
          "href": "/project/build-ball"
        }}>{`Build Ball`}</a></p>
      <Spacer height={24} mdxType="Spacer" />
      <aside>
        <hr></hr>
        <p>{`More projects coming soon.`}</p>
      </aside>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      